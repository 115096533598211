import React from 'react'
import Logo2 from '../images/Background 2.svg'
import Dice from '../images/Dice.svg'
import Poker from '../images/poker-cards_main.svg'
import Fpl from '../images/Fantasy Card.svg'
import './secondsection.css'
export default function SecondSection() {
    return (
        <div id='games'>
          <div className='secondsection__set'>
            <p className='secondsection__text'>Games</p>
            <div className="secondsection__block">              
              <div className='secondsection__segment'>                  
                <img className='secondsegment__image1'  src={Poker} alt='poker' />
                <p className='secondsegment__text'>Whot</p>
              </div>
              <div className='secondsection__segment'>                  
                <img className='secondsegment__image3'  src={Fpl} alt='poker' />
                <p className='secondsegment__text secondsegment__fpl'>FPL</p>
              </div>
              <div className='secondsection__segment secondsection__align'>
                <img className='secondsegment__image2 '  src={Dice} alt='dice' /> 
                <p className='secondsegment__text'>Ludo</p>
                <div className="coming__soon">
                  <p className='secondsegment__text2'>Coming soon</p>
                </div>
              </div>   
            </div>    
          </div>
               
        </div>
    )
}
