import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/background 1.svg"
import Cube from "../images/Cube.svg"
import Card from "../images/CARD@3x.png"
import Chip from "../images/Chip.svg"
import Apple from "../images/Apple.svg"
import Playstore from "../images/Playstore.svg"
import Coin from "../images/Group 90 Copy.svg"
import Tete from "../images/Tete Logo.svg"
import "./header.css"

const Header = () => (
  <header className="header__container">
    <img className="header__image" src={Logo} alt="logo" />
    <img className="header__card" src={Card} alt="card"></img>
    <img className="header__chip" src={Chip} alt="chip"></img>

    <div className="header__block">
      <img className="header__coin" src={Coin} alt="coin"></img>
      <div className="navbar">
        <Link to="/">
          <img className="tete__logo" src={Tete} alt="tete"></img>
        </Link>
        {/* <div class="hamburger-menu">
          <input id="menu__toggle" type="checkbox" />
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>

          <ul class="menu__box">
            <li>
              <Link className="menu__item" to="/">
                How to play
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/aboutus">
                Games
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/aboutus">
                FAQ
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/privacy">
                Privacy
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/terms">
                Terms and conditions
              </Link>
            </li>
          </ul>
        </div> */}
        <div className="nav__content">
          <Link className="nav__links" to="#how">
            How to play
          </Link>
          <Link className="nav__links" to="#games">
            Games
          </Link>
          <Link className="nav__links" to="#faq">
            FAQ
          </Link>
          {/* <Link className="nav__links" to="/privacy">
            Privacy
          </Link> */}
        </div>
      </div>
      <div className="header__set">
        <div className="header__content">
          <div className="header__textwrapper">
            <img className="cube" src={Cube} alt="cube" />
            <p className="header__text">
              Play with little, <br></br>win big.
            </p>
          </div>
          <div className="available">
            <p className="header__subtext">Download Now.</p>
            <div className="logo__container">
              <a href="https://apps.apple.com/us/app/id1524548446">
                <img className="apple__logo" src={Apple} alt="apple"></img>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=ng.teteng.app"
              >
                <img
                  className="playstore__logo"
                  src={Playstore}
                  alt="android link"
                ></img>
              </a>
              {/* <div class="box">
	              <a class="popupbutton" href="#popup1">Click Here For Android Instructions !!</a>
              </div>
              <div id="popup1" class="overlay">
              	<div class="popup">
              		<h2>Steps To Download On Android</h2>
              		<a class="close" href="#">&times;</a>
              		<div class="content">
              			1) Download the dropbox link above.
              		</div>
                  <div class="content">
              			2) Locate it from the file manager.
              		</div>
                  <div class="content">
              			3) Install it from there.
              		</div>
                  
              	</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
