import React from 'react'
import './firstsection.css'
import Card1 from '../images/Choose.svg'
import Card2 from '../images/Puzzle.svg'
import Card3 from '../images/Cashout-1.svg'

export default function FirstSection() {
    return (
        <div id='how'>
            <div className='firstsection__block'>
              <div className='firtsection__set'>
                <p className='main__text'>How to play</p>
                <div className='session__content'>
                  <div className='segment'>
                      <img className='segment__image' src={Card1} alt='card1'></img>
                      <p className='segment__text'>Choose a game</p>
                      <p className='segment__subtext'>Select the type of game you would like to play</p>
                  </div>
                  <div className='segment'>
                      <img className='segment__image' src={Card2} alt='card1'></img>
                      <p className='segment__text'>Tete</p>
                      <p className='segment__subtext'>Play against other people or friends.</p>
                  </div>
                  <div className='segment'>
                      <img className='segment__image' src={Card3} alt='card1'></img>
                      <p className='segment__text'>Cashout</p>
                      <p className='segment__subtext'>Have fun winning and make money doing it.</p>
                    </div>
                </div>       
              </div>
            </div>
        </div>
    )
}
