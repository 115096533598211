import React, { useState } from "react"
import FAQ from "./FAQ"
import "./thirdsection.css"
export default function ThirdSection() {
  const [faqs, setfaqs] = useState([
    {
      question: "What is Tete?",
      answer:
        "Tete is a gaming company that offers different games to be played online with other users.",
      open: false,
    },
    // {
    //   question: 'Is it a gaming or gambling app?',
    //   answer: 'Tete is a service that offers both gambling and gaming experience. We provide you the avenue to make money off having fun and playing games.',
    //   open: false
    // },
    // {
    //   question: 'How do I fund and withdraw with my account?',
    //   answer: 'We offer a fast and secure payment platform for our users via Paystack. Our users can make deposits with Card, Bank, USSD, Visa QR and transfers to get credited instantly in their Tete wallets. We are also working on other effective means to fund wallets. Cashout is instant with just 3 clicks.',
    //   open: false
    // },
    {
      question: "Do I need a high tech mobile device?",
      answer:
        "No you don’t, our app has been created to work on all smart phones without errors provided you have steady internet connection.",
      open: false,
    },
    {
      question: "Do I need internet connection? ",
      answer:
        "Yes!, you need to be connected to the internet to play other users but it takes little to no data. Moreover we give our frequent customers free data weekly.",
      open: false,
    },
    {
      question: "How do I play the games?",
      answer:
        'We offer games that are easy to understand. There is also a "Game rule" tab that describes the game in detail.',
      open: false,
    },
    {
      question:
        "What happens if I lose internet connection during a game play?",
      answer:
        "We offer a 2 minutes reconnection window to continue the game otherwise, the game is automatically forfeited.",
      open: false,
    },
    {
      question: "Do all my winnings come to me?",
      answer:
        "We charge 20% of the profit which can be as low as 20 naira and capped at 100 naira irrespective of the profit, depending on the stake of the game.",
      open: false,
    },
  ])

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open
        } else {
          faq.open = false
        }

        return faq
      })
    )
  }

  return (
    <div id="faq">
      <p className="thirdsection__text">Frequently asked questions</p>
      <div className="thirdsection__block">
        <div className="faqs">
          {faqs.map((faq, i) => (
            <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
    </div>
  )
}
